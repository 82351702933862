<template>
  <div>
      <LoadingSpinner :loading="loading"> </LoadingSpinner>

      <div class="p-3 mt-3" style="max-width:560px">
        <h5 v-if="hasMultiLocations()"> {{ getLocationName(order.locationId) }} </h5>
        <h3>{{getOrderTypeText()}}</h3>
        <h5>Ordre #{{order.orderId}}</h5>
        <div>
          <span :class="order.orderStatus.toLowerCase()"> {{getOrderStatusText()}} </span>
          <button v-if="isSuperAdmin && !isOrderCompleted" type="button" class="bo_btn ms-4" @click="markAsCompleted()">Merk som fullført</button>
        </div>

        <div class="mt-3">
          <div v-if="order.name">
            <div>Navn: <strong> {{ getNameText(order.name) }} </strong></div>
          </div>
          <div v-if="order.mobileNumber">
            <div>Telefonnr: <strong> {{ getPhoneNrText(order.mobileNumber) }} </strong></div>
          </div>
          <div v-if="order.tableName">
            <div>Bord: <strong> {{order.tableName}} </strong></div>
          </div>
        </div>

        <div>Bestilt via: {{ getChannelText() }}</div>
        <div>Bestilt: {{ getDateTimeText(order.orderedTime) }}</div>

        <div v-if="order.orderType == 'PICKUP'">Hentes: {{ getPickupOrDeliveryTimeText(order) }}</div>
        <div v-else-if="order.orderType == 'DELIVERY'">Leveres: {{ getPickupOrDeliveryTimeText(order) }}</div>

        <div>Betalt med: {{ getPaymentTypeText() }}</div>
        <div>Totalt: <strong> {{ displayTwoDecimals(order.totalAmount) }} </strong></div>

        <div v-if="order.tipsAmount > 0">
          <div>Tips: {{ displayTwoDecimals(order.tipsAmount) }} </div>
        </div>
        <div v-if="order.usedPoints > 0">
          <div>Rabatt: -{{ displayTwoDecimals(order.usedPoints) }} </div>
        </div>
        <div v-if="order.tipsAmount > 0 || order.usedPoints > 0">
          <div>Å betale: <strong> {{ displayTwoDecimals(order.totalAmount + order.tipsAmount - order.usedPoints) }} </strong></div>
        </div>

        <div v-if="showRefundCartItems()" class="refund mt-3 mb-3">
          <span style="font-weight: bold">Følgende har blitt refundert:</span>
          <div v-for="cartItem in refundedCartItems" :key="cartItem.id" :id="cartItem.id" class="refundCartItem ps-3 pe-3">
            <div class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center">
                <div>
                  <div>
                    -1 x {{cartItem.itemName}}
                  </div>
                  <div v-if="cartItem.selectedItemSubselection !== ''">
                    {{ cartItem.selectedItemSubselection}}
                  </div>
                  <div v-if="cartItem.spicyLevel !== ''">
                    {{ cartItem.spicyLevel}}
                  </div>
                  <div v-if="cartItem.comment !== ''">
                    {{ cartItem.comment}}
                  </div>
                </div>
              </div>
              <div>
                -{{ displayTwoDecimals(getPriceWithVat(cartItem)) }}
              </div>
            </div>
          </div>
          <div v-if="order.refundedTipsAmount > 0" class="refundCartItem ps-3 pe-3 mb-2">
            <div class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center">
                <div> -Tips </div>
              </div>
              <div>
                -{{ displayTwoDecimals(order.refundedTipsAmount) }}
              </div>
            </div>
          </div>
          <div class="d-flex align-items-center justify-content-between pe-3">
            <div class="d-flex align-items-center">
              <span>Totalt:</span>
            </div>
            <div>
              <span style="font-weight: bold"> -{{ displayTwoDecimals(refundedTotalAmount) }} </span>
            </div>
          </div>
        </div>

        <div class="mt-3" style="white-space: pre-wrap; background-color:#F5F5F5">{{order.description}}</div>

        <div v-if="showRefundInfo">
            <div v-if="showRefundBtn()" class="bo_savebtn_container mt-2 mb-5">
              <hr/>
              <button type="button" class="bo_btn" @click="goToOrderRefundPage()">Refunder</button>
            </div>
            <div v-else>
              <div v-if="order.channel == 'KIOSK'" class="bo_savebtn_container mt-2 mb-5">
                <hr/>
                <span v-if="order.settled" style="font-style: italic;">Ordren har blitt tatt med i dagsoppgjøret og kan ikke refunderes.</span>
                <span v-else style="font-style: italic;">Ordren har blitt bestilt via selvbetjeningskiosk og kan ikke refunderes her.</span>
              </div>
            </div>

            <div v-if="showInfoRefundNotPossible()" class="bo_savebtn_container mt-2 mb-5">
              <hr/>
              <span style="font-style: italic;" v-if="order.usedPoints > 0">Ordren har blitt betalt helt eller delvis med bonuspoeng og kan ikke refunderes.</span>
              <span style="font-style: italic;" v-else>Ordren har blitt tatt med i dagsoppgjøret og kan ikke refunderes.</span>
            </div>
        </div>
      </div>
  </div>
</template>

<script>
import OrderService from "@/services/order/OrderService";
import OrderUtil from "@/util/OrderUtil";
import VatUtil from "@/util/VatUtil";
import MerchantUtil from "@/util/MerchantUtil";
import UserUtil from "@/util/UserUtil";
import LocationUtil from "@/util/LocationUtil";
import MathUtil from "@/util/MathUtil";
import LoadingSpinner from "@/components/other/LoadingSpinner.vue";

export default {
  components: {LoadingSpinner},
  props: {
    dataForOrderDetail: Object
  },
  emits: ['close'],
  data() {
    return {
      loading: false,
      showRefundInfo: true, //default:=true
    };
  },
  computed: {
    isSuperAdmin(){
      return UserUtil.isSuperAdmin();
    },
    isOrderCompleted(){
      return this.order.orderStatus === 'COMPLETED';
    },
    orderDocumentId(){
      return this.dataForOrderDetail.order.id;
    },
    order(){
      return this.dataForOrderDetail.order;
    },
    vat(){
      return this.dataForOrderDetail.vat;
    },
    refundedCartItems(){
      return this.getRefundedCartItems();
    },
    refundedTotalAmount(){
      return this.order.refundedTotalAmount;
    }
  },
  methods:{
    hasMultiLocations(){
      return LocationUtil.hasMultiLocations();
    },
    getLocationName(locationId){
      let location = LocationUtil.getLocation(locationId);
      return location.name;
    },
    getRefundedCartItems(){
      if(this.order.refundedCartItems !== undefined){
        let refundedCartItems = this.order.refundedCartItems;
        return refundedCartItems;
      } else {
        return [];
      }
    },
    getPriceWithVat(cartItem){
      let vatRate = VatUtil.getVatRate(this.vat, this.order.orderType, cartItem.itemType);
      let discountPercent = cartItem.discountPercent? cartItem.discountPercent : 0;
      let priceWithVat = OrderUtil.getPriceWithVat(vatRate, cartItem.price) * (100 - discountPercent) / 100;
      if(discountPercent == 0){
        return Math.round(priceWithVat); //make it exact as it is in order description.
      } else {
        return MathUtil.roundTwoDecimals(priceWithVat);
      }
    },
    getOrderTypeText(){
      return OrderUtil.getOrderTypeText(this.order.orderType).toUpperCase();
    },
    getNameText(name){
      return OrderUtil.getNameText(name);
    },
    getPhoneNrText(mobileNumber){
      return OrderUtil.getPhoneNrText(mobileNumber);
    },
    getOrderStatusText(){
       return OrderUtil.getOrderStatusText(this.order.orderStatus).toUpperCase();
    },
    getChannelText(){
      return OrderUtil.getChannelText(this.order.channel);
    },
    getPaymentStatusText(){
       return OrderUtil.getPaymentStatusText(this.order.payments);
    },
    getPaymentTypeText(){
      //For the case ONLY one payment type!
      if(this.order.payments !== undefined && this.order.payments.length > 0){
        var paymentTypes = '';
        for(var i = 0; i <  this.order.payments.length; i++){
          let payment = this.order.payments[i];
          paymentTypes = paymentTypes + payment.paymentType + ", ";
        }
        paymentTypes = paymentTypes.substring(0, paymentTypes.length - 2); //Remove last ", "
        return OrderUtil.getPaymentTypeText(paymentTypes);
      }else{
        return 'Ikke betalt';
      }
    },
    getDateTimeText(dateTime){
       return OrderUtil.getDateTimeText(dateTime);
    },
    getPickupOrDeliveryTimeText(order){
      let pickupOrDeliveryTime = order.pickupOrDeliveryTime;
      if (pickupOrDeliveryTime) {
          return OrderUtil.getDateTimeTextShort(pickupOrDeliveryTime);
      } else {
          if(order.orderType == 'PICKUP') {
            let pickupTimeOption = order.pickupTimeOption;
            if(pickupTimeOption == 'whenFoodIsReady') {
              return "Får melding når maten er klar";
            }
          } else {
            //Delivery
            let deliveryTimeOption = order.deliveryTimeOption;
            if(deliveryTimeOption == 'asSoonAsPossible') {
                return "Så fort som mulig";
            }
          }
      }
    },

    /**
     * Only allow refund for orders that are
     * 1. TAKEAWAY/ALL Merchant
     * 2. Channel := WEBSHOP or KIOSK
     * 3. not settled yet AND
     * 4. paid AND
     * 5. refundedCartItems length is 0 dvs. allow refund only once.
     */
    showRefundBtn(){
      if( (this.useFunctionality() === 'TAKEAWAY' || this.useFunctionality() === 'ALL') &&
          (this.order.channel === 'WEBSHOP') &&
          this.order.settled === undefined &&
          OrderUtil.getPaymentStatus(this.order.payments) === 'PAID' &&
          this.order.refundedCartItems === undefined &&
          (this.order.usedPoints === undefined || this.order.usedPoints == 0)){
        return true;
      } else {
        return false;
      }
    },
    showInfoRefundNotPossible(){
      if((this.useFunctionality() === 'TAKEAWAY' || this.useFunctionality() === 'ALL') &&
          this.order.channel === 'WEBSHOP' &&
          this.order.settled === true &&
          OrderUtil.getPaymentStatus(this.order.payments) === 'PAID' &&
          this.order.refundedCartItems === undefined ||
          this.order.usedPoints > 0){
        return true;
      } else {
        return false;
      }
    },
    showRefundCartItems(){
      if( (this.useFunctionality() === 'TAKEAWAY' || this.useFunctionality() === 'ALL') &&
          (this.order.channel === 'WEBSHOP' || this.order.channel === 'KIOSK') &&
          this.order.refundedCartItems !== undefined){
        return true;
      } else {
        return false;
      }
    },
    useFunctionality(){
      return MerchantUtil.useFunctionality();
    },
    goToOrderRefundPage(){
      this.$emit('close');

      this.$router.push({ path: 'orderRefund', query: {
          orderDocumentId: this.orderDocumentId
        }
      });
    },
    markAsCompleted(){
      this.loading = true;

      OrderService.updateOrderStatus(this.orderDocumentId, 'COMPLETED').then(
          (response) => {
            let result = response.data.result;
            if(result == 'SUCCEEDED'){
              this.$router.go(); //Reload current page
            } else {
              console.log("Order markAsCompleted failed");
            }
            this.loading = false;
          },
          (error) => {
            console.log(error);
          }
      );
    },
    displayTwoDecimals(num){
      if(num > 0 || num < 0){
        return MathUtil.displayTwoDecimals(num);
      }else {
        return num;
      }
    }
  }
}
</script>

<style scoped>
  .refund {
    border: solid 2px #dd143b;
    padding: 5px;
  }

  .refundCartItem {
    padding-top: 5px;
    padding-bottom: 5px;
    border-top: #CCCCCC;
    border-bottom: #CCCCCC;
    border-bottom: 1px  solid #CCCCCC;
  }
</style>
