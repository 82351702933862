<template>
  <div class="container">
      <LoadingSpinner :loading="loading"> </LoadingSpinner>

      <div class="row vertical-align mt-3 ms-1 me-1">
          <div class="col-sm-6">
                <h3>X-rapport</h3>
                <div v-if="!loading">
                    <div v-if="hasMultiLocations()" class="mt-5">
                      <span class="ingredient-title">Velg avdeling</span>
                      <select class="form-select" v-model="locationId" @change="showTerminals()">
                        <option v-for="(location, index) in locations" :key="index" :value="location.id">{{ location.name }}</option>
                      </select>
                    </div>

                    <div v-if="terminals.length > 0">
                          <SelectTerminal :terminals="terminals" :terminal="selectedTerminal" @setSelectedTerminal="setSelectedTerminal"/>
                          <button type="button" class="bo_btn" :class="isObjectEmpty(xReportCounters) ? 'mb-5' : 'mb-0'"
                                  @click="showXReport"> Vis X-rapport </button>

                          <div v-if="xReportCounters != undefined">
                              <div v-if="isXReportCountersEmpty" class="mt-4 mb-5">
                                <p>
                                  Ingen omsetning fra dette kassepunktet siden forrige oppgjør.
                                </p>
                              </div>
                              <div v-else>
                                <ShowXReport :location="getLocation" :terminal="selectedTerminal" :xReportCounters="xReportCounters" :company="company" :vat="vat"/>
                                <button type="button" class="bo_btn mb-5" @click="createZReport">Ta dagens oppgjør for {{ getSettleBtnText() }}</button>
                                <ConfirmDialogue ref="confirmDialogue"></ConfirmDialogue>
                              </div>
                          </div>
                    </div>
                    <div v-else class="mt-4 mb-5">
                      <p>
                        Ingen kassepunkt er satt opp for denne avdelingen.
                      </p>
                    </div>
                </div>
          </div>
          <div v-if="!loading && selectedTerminal.terminalType == 'KIOSK'" class="col-sm-6 d-flex justify-content-center mb-5 pt-0 ps-4 pe-4">
            <span style="font-size: 19px">Dagsoppgjør for nettbutikken og selvbetjeningskiosk blir gjort automatisk ved dagens slutt hvis du ikke allerede har gjort det.</span>
          </div>
      </div>
  </div>
</template>

<script>
  import LoadingSpinner from "@/components/other/LoadingSpinner";
  import SelectTerminal from "@/components/report/SelectTerminal";
  import ShowXReport from "@/components/report/xreport/ShowXReport";
  import ReportService from "@/services/report/ReportService";
  import LocationUtil from "@/util/LocationUtil";
  import TerminalService from "@/services/terminal/TerminalService";
  import ConfirmDialogue from "@/components/other/ConfirmDialogue";
  import MerchantUtil from "@/util/MerchantUtil";

  export default {
    components: {LoadingSpinner, SelectTerminal, ShowXReport, ConfirmDialogue},
    data() {
      return {
        loading: true,

        locationId: '',
        locations: [],
        merchant: '',
        company: {},
        terminals: [],
        selectedTerminal: {},
        vat: {},
        xReportCounters: undefined
      };
    },
    computed: {
      getLocation(){
        return LocationUtil.getLocation(this.locationId);
      },
      isXReportCountersEmpty() {
        if(this.xReportCounters.vippsPickupVat == 0 &&
            this.xReportCounters.vippsDineInVat == 0 &&
            this.xReportCounters.vippsDeliveryVat == 0 &&
            this.xReportCounters.vippsDeliveryServiceVat == 0 &&
            this.xReportCounters.vippsPickupBrutto == 0 &&
            this.xReportCounters.vippsDineInBrutto == 0 &&
            this.xReportCounters.vippsDeliveryBrutto == 0 &&
            this.xReportCounters.vippsDeliveryServiceBrutto == 0 &&

            this.xReportCounters.cardPickupVat == 0 &&
            this.xReportCounters.cardDineInVat == 0 &&
            this.xReportCounters.cardDeliveryVat == 0 &&
            this.xReportCounters.cardDeliveryServiceVat == 0 &&
            this.xReportCounters.cardPickupBrutto == 0 &&
            this.xReportCounters.cardDineInBrutto == 0 &&
            this.xReportCounters.cardDeliveryBrutto == 0 &&
            this.xReportCounters.cardDeliveryServiceBrutto == 0 &&

            this.xReportCounters.refundReceiptsCount == 0 &&
            this.xReportCounters.refundReceiptsTotalAmount == 0){
          return true;
        } else {
          return false;
        }
      }
    },
    mounted() {
      ReportService.getDataForReportGui().then(
          (response) => {
            let dataForGui = response.data;
            //console.log("dataForGui: " +  JSON.stringify(dataForGui));
            this.locations = dataForGui.locations;
            this.company = dataForGui.company;
            this.vat = dataForGui.vat;
            this.merchant = dataForGui.merchant;

            this.locationId = this.locations.length ? this.locations[0].id : '';

            this.loading = false;

            if(this.locations.length == 1){
              this.showTerminals();
            }
          },
          (error) => {
            console.log(error);
          }
      );
    },
    methods: {
      hasMultiLocations(){
        return LocationUtil.hasMultiLocations();
      },
      showTerminals() {
        this.loading = true;

        TerminalService.getTerminalsForLocation(this.locationId).then(
            (response) => {
              let filteredTerminals = [];

              let availableTerminals = response.data;
              for(let i = 0; i < availableTerminals.length; i++) {
                let terminal = availableTerminals[i];
                if(terminal.terminalType != 'KDS'){
                  filteredTerminals.push(terminal);
                }
              }

              if(this.useFunctionality() === 'TAKEAWAY' || this.useFunctionality() === 'ALL'){
                let webshop = {
                  terminalId: 'WEBSHOP',
                  locationId: this.locationId
                };
                filteredTerminals.push(webshop);
              }

              this.terminals = filteredTerminals;
              this.selectedTerminal = this.terminals.length > 0 ? this.terminals[0] : {};

              this.loading = false;
            },
            (error) => {
              console.log(error);
            }
        );
      },
      setSelectedTerminal(terminal){
        this.selectedTerminal = terminal;
      },
      useFunctionality(){
        return MerchantUtil.useFunctionality();
      },
      getSettleBtnText(){
        if(this.selectedTerminal.terminalId == 'WEBSHOP'){
            return 'nettbutikken';
        } else {
            return this.selectedTerminal.deviceName;
        }
      },
      showXReport() {
        this.loading = true;
        ReportService.getXReportCounters(this.selectedTerminal.locationId, this.selectedTerminal.terminalId).then(
            (response) => {
              this.xReportCounters= response.data;
              //console.log('xReportCounters: ' +  JSON.stringify(this.xReportCounters));
              this.loading = false;
            },
            (error) => {
              console.log(error);
            }
        );
      },
      isObjectEmpty(jsonObject){
        return JSON.stringify(jsonObject) === '{}';
      },
      async createZReport(){
        let title = 'Ta dagens oppgjør for ' + this.getSettleBtnText();
        const ok = await this.$refs.confirmDialogue.show({
          title: title,
          message: 'Vil du generere oppgjørsrapport for dette kassepunktet ' + this.getSettleBtnText() + '?',
          okButton: 'Ja',
          cancelButton: 'Avbryt'
        })
        if(ok) {
          ReportService.createZReport(this.selectedTerminal.locationId, this.selectedTerminal.terminalId).then(
              (response) => {
                if(response.data != null){ //Z-report is created, response.data :== documentId
                  this.showXReport(); //refresh GUI
                }else {
                  console.log("Generering av oppgjørsrapport for kassepunktet feiler.");
                }
              },
              (error) => {
                console.log(error);
              }
          );
        } else {
          //Cancel, do nothing
        }
      }
    }
  };
</script>
